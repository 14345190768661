import React,{useState,useEffect}  from 'react'
import { navigate } from "gatsby"
import { Player } from 'video-react'
import HomeVideoMP4 from "../assets/video/HTB_homepage-animation_v8.mp4"

class HomeVideo extends React.Component {

render() {
  return(
      <>
      <div className="video-wrapper">
      <video 
        id="vidRef" 
        width="100%" 
        height="100%" 
        loop="true"
        preload="auto" 
        muted="true"
        autoplay="true" 
        playsinline="true"
      >
        <source src={require("../assets/video/HTB_homepage-animation_v8.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </div>
      </>
    )
}
}

export default HomeVideo
