import React, { useState,useEffect } from "react"
import Select from "react-select"

const HowCanWeHelp = ({ howCanWeHelpCategory }) => {

  const [categoriesSelected, categoriesSetSelected] = useState()
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const products = []
  const categories = []
  const newCategories = []
  const emptyCats=[]

  const handleSubmit=(e)=>{
    e.preventDefault();
    if(categoriesSelected){
      window.location = categoriesSelected;
    } else{
      window.location = productsOptions[0].value;
    }

  }


  const customStyles = {
    option: (provided,selectedOption) => ({
      ...provided,
      color: "#666",
      backgroundColor: '#fff',
      fontSize: '22px',
      fontWeight: '200',
      padding: '7px 20px',
    }),
    control: (provided,selectedOption) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '0px',
      borderRadius: '30px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#666",
      backgroundColor: '#fff',
      fontSize: '22px',
      fontWeight: '200',
      padding: '7px 20px',
    }),
  }

  howCanWeHelpCategory.map(prod =>
    products.push({
      value: prod.name,
      label: prod.name,
    })
  )

  //add all options for all selectors to the sel
  howCanWeHelpCategory.map(link =>
    link.howHelped.nodes.map(help =>
      categories.push({
        value: help.howCanWeHelpUrls.howCanWeHelpUrl,
        label: help.title,
        id: link.name
      })
    )
  )

const [productsSelected, productsSetSelected] = useState(products[0].label)
const [productsOptions, setProductOptions] = useState(categories)

const updateLoad=(e)=>{
  setProductOptions(emptyCats)

  if(url==="/business/"){
    products[4].label="a business owner"
    categories.map(items => items.id === "a business owner" ? (
        newCategories.push({
          value: items.value,
          label: items.label,
          id: items.id
        })
        ) : (
          null
        )
      )
  }
  else{
    categories.map(items => items.id === "an individual" ? (
        newCategories.push({
          value: items.value,
          label: items.label,
          id: items.id
        })
        ) : (
          null
        )
      )
  }

  setProductOptions(newCategories)
}

const handleCatSelect=(e)=>{

  categoriesSetSelected(e.value)
  console.log("what"+productsOptions[0].label)
  productsOptions[0].label=e.label

}


const handleSelect=(e)=>{

  productsSetSelected(e.label)

  setProductOptions(emptyCats)

  categories.map(items => items.id === e.label ? (
      newCategories.push({
        value: items.value,
        label: items.label,
        id: items.id
      })
      ) : (
        null
      )
    )

  setProductOptions(newCategories)

}

  useEffect(() => {
    updateLoad()
  }, []);


  return (
    <>
      {howCanWeHelpCategory ? (
        <section className="content navy-background">
          <div className="sixty-spacer"></div>

          <div className="content-block">
            <div className="full-width-container">
              <div className="full-width-container">
                <div className="cta-help-title-holder">
                  Quickly find what you need
                </div>
              </div>
              <div className="full-width-container">
                <div className="cta-help-field-select-container clearfix">
                  <form onSubmit={handleSubmit} >
                    <div className="cta-help-field-holder">
                      <label htmlFor="im-select">I'm...</label>
                      <Select styles = { customStyles } options={products} defaultValue={products[4]} onChange={handleSelect}/>
                    </div>
                    <div className="cta-help-field-holder">
                      <label htmlFor="for-select">Looking for...</label>

                      <Select styles = { customStyles } options={productsOptions} value={productsOptions[0]} onChange={handleCatSelect}/>
                    </div>
                    <div className="cta-help-submit-holder">
                      <input type="submit" value="Let's go" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="eighty-spacer"></div>
        </section>
      ) : null}
    </>
  )
}

export default HowCanWeHelp
