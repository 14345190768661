import React,{useEffect, useState } from "react"
import Slider from "react-slick"
import HomeVideo from '../../components/HomeVideo'
import { navigate } from "gatsby"

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

const slickSettings = {
  fade: true,
  autoplay: true,
  pauseOnHover: false,
  speed: 500,
  autoplaySpeed: 7000,
  accessibility: false,
  useCSS: true,
  swipe: true,
  swipeToSlide: true,
  arrows: false,
  dots: true,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false,
      },
    },
  ],
}


const HomeHeroBanner = ({ homeBannerGroup }) => {

  return (

    <section className="content">
    <Slider className="full-width-banner-short" {...slickSettings}>
      {homeBannerGroup.length
        ? homeBannerGroup.map((banners, index) => (
          <div key={index}>
            <div className="slick-content-container">
                <div className="slick-inner-content-holder">

                    <div className="slick-title-holder slickStagger-0 slide-visible">
                      <h3 className="slickStagger-0" dangerouslySetInnerHTML={{ __html: banners.homeBanner.title}}/>
                    </div>

                    <div className="slick-text-holder slickStagger-0 slide-visible">
                      <p
                        className="slickStagger-0 hide-on-responsive"
                        dangerouslySetInnerHTML={{ __html: banners.homeBanner.content }}
                      />
                    </div>
                    {banners.homeBanner.bannerDetails.bannerLinkText ?
                    <div className="slick-link-holder slickStagger-2 slide-visible">
                    <a href={banners.homeBanner.bannerDetails.bannerUrl}>
                      {banners.homeBanner.bannerDetails.bannerLinkText}
                    </a>
                  </div>
                  :
                  null
                  }
                </div>
            </div>
            <>
            {(index === 0) ?
              <>
              <div className="slick-full-video-holder"  key={index}>

                  <HomeVideo/>

              </div>
              {/*
              <div className="slick-full-image-holder show-on-responsive">
                <img
                  src={`/htbcontent/uploads/${banners.homeBanner.featuredImage.mediaDetails.file}`}
                  alt={banners.homeBanner.featuredImage.altText}
                  title={banners.homeBanner.featuredImage.title}
                ></img>
              </div>
              */}
              </>
              :
              <>
              <div className="slick-full-image-holder show-on-responsive">
              <img
                src={`/htbcontent/uploads/${banners.homeBanner.featuredImage.mediaDetails.file}`}
                alt={banners.homeBanner.featuredImage.altText}
                title={banners.homeBanner.featuredImage.title}
              ></img>
              </div>
              <div
                className="slick-full-image-holder hide-on-responsive"
                style={{
                  backgroundImage:
                    "url(/htbcontent/uploads/" +
                    banners.homeBanner.featuredImage.mediaDetails.file +
                    ")",

                }}
              >
              </div>
            </>}
              </>


          </div>
          ))
        : null}
    </Slider>
    </section>
  )
}

export default HomeHeroBanner
